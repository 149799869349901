import {Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {PartnerCardType} from '@mgp-fe/shared/core-api/queries/stripe/partner-cards.ts';

export default {
	address: {
		validate: '/address/is_valid',
	},
	addressBook: {
		list: '/user_address_books',
		item: (id: string) => `/user_address_books/${id}`,
	},
	affiliate: {
		list: '/affiliates',
		landingPageVisits: '/affiliate_landing_page_visits',
		approve: (id: string) => `/affiliates/${id}/toggle_approve`,
		item: (id: string) => `/affiliates/${id}`,
	},
	affiliateReport: {
		item: '/affiliate_reports',
	},
	authentication: {
		login: '/authentication/login',
		forgotPassword: '/forgot_password',
		resetPassword: '/reset_password',
		sendSignLink: '/authentication/send-sign-link',
	},
	cart: {
		my: {
			item: '/carts/my',
			void: '/carts/my/void',
			applyVoucher: '/carts/my/apply_voucher',
			applyCoupon: '/carts/my/apply_coupon',
			applyPromotion: '/carts/my/apply_promotion',
			availablePromotions: '/carts/my/available_promotions',
			shippingMethods: '/carts/my/shipping_methods',
			shipping: '/carts/my/shipping',
			payment: '/carts/my/payment',
			complete: '/carts/my/complete',
			onboardingCheckout: '/carts/my/onboarding',
			onboardingOrderSetupCheckout: '/carts/my/onboarding_order_setup',
			billingPartnerLocation: '/carts/my/billing_partner_location',
			items: {
				list: '/cart/my/items',
				item: (id: string) => `/cart/my/items/${id}`,
				itemChangeThickness: (id: string) => `/cart/my/items/${id}/change_thickness`,
				itemReduce: (id: string) => `/cart/my/items/${id}/reduce`,
				itemDelete: (id: string) => `/cart/my/items/${id}/delete`,
				removeVoucher: (id: string) => `/cart/my/items/${id}/remove_voucher`,
			},
			checkout: {
				shipping: '/carts/my/shipping',
				shippingMethods: '/carts/my/shipping_methods',
				payment: '/carts/my/payment',
				complete: '/carts/my/complete',
			},
			removeCoupons: '/carts/my/remove_coupons',
			removePromotions: '/carts/my/remove_promotions',
		},
	},
	category: {
		list: '/categories',
	},
	customers: {
		listForPartner: '/partner/customers',
		itemForPartner: (id: string) => `/partner/customers/${id}`,
	},
	hubspot: {
		timezones: {
			list: '/hubspot/timezones',
		},
	},
	invitations: {
		list: '/invitations',
		item: (id: string) => `/invitations/${id}`,
		detail: (id: string) => `/invitations/${id}/detail`,
		accept: (id: string) => `/invitations/${id}/accept`,
		decline: (id: string) => `/invitations/${id}/decline`,
		resend: (id: string) => `/invitations/${id}/resend`,
	},
	me: {
		index: '/me',
		changePassword: '/me/change_password',
		disable2fa: '/me/disable-2fa',
		oauth: {
			index: '/me/oauth',
			item: (id: string) => `/me/oauth/${id}`,
		},
	},
	mouthguardDesigns: {
		list: '/mouthguard_designs',
		athleteTeams: '/mouthguard_designs/athlete_teams',
		item: (id: string) => `/mouthguard_designs/${id}`,
		changePublicAccess: (id: string) => `/mouthguard_designs/${id}/change_public_access`,
	},
	newsletter: {
		signup: '/newsletter/signup',
	},
	notes: {
		index: '/notes',
		item: (id: string) => `/notes${id}`,
	},
	orders: {
		list: '/orders',
		item: (id: string) => `/orders/${id}`,
		productionLabel: (id: string) => `/orders/${id}/production_label`,
	},
	partners: {
		list: '/partners',
		signup: '/partners/sign_up',
	},
	partnerLocations: {
		list: '/partner_locations',
		item: (id: string) => `/partner_locations/${id}`,
		listPublic: '/partner_locations/public',
	},
	partnerOrganizations: {
		list: '/partner_organizations',
		item: (id: string) => `/partner_organizations/${id}`,
	},
	partnerOrganizationLocations: {
		list: '/partner_organization_locations',
		item: (id: string) => `/partner_organization_locations/${id}`,
	},
	partnerUserPartnerLocations: {
		list: '/partner_user_partner_locations',
		item: (id: string) => `/partner_user_partner_locations/${id}`,
	},
	partnerUserPartnerOrganization: {
		list: '/partner_user_partner_organizations',
		item: (id: string) => `/partner_user_partner_organizations/${id}`,
	},
	products: {
		list: '/products',
		item: (id: string) => `/products/${id}`,
	},
	promotion: {
		list: '/promotions',
		item: (id: string) => `/promotions/${id}`,
		itemDetailed: (id: string) => `/promotions/${id}/detailed`,
	},
	promotionActions: {
		item: (id: string) => `/promotion_actions/${id}`,
	},
	scanRequests: {
		list: '/scan_requests',
		item: (id: string) => `/scan_requests/${id}`,
	},
	scans: {
		list: '/scans',
		item: (id: string) => `/scans/${id}`,
	},
	search: {
		multiTable: '/search/multi-table',
	},
	settings: {
		item: (key: string) => `/settings/${key}`,
	},
	sendSignInLink: '/send_sign_link',
	shipments: {
		single: {
			cancelShippingLabel: (id: string) => `/shipments/single/${id}/cancel_shipping_label`,
			generateShippingLabel: (id: string) => `/shipments/single/${id}/generate_shipping_label`,
		},
	},
	signUpLinks: {
		list: '/sign_up_links',
		item: (id: string) => `/sign_up_links/${id}`,
		createCustomer: (id: string) => `/sign_up_links/${id}`,
	},
	stripe: {
		myCards: '/stripe/my-cards',
		partnerCards: (type: PartnerCardType, id: string) => `/stripe/${type}/cards/${id}`,
		partnerCheckoutCards: (locationId: string) => `/stripe/checkout-cards/${locationId}`,
		mySetupIntent: '/stripe/setup-intent',
		partnerSetupIntent: (type: 'location' | 'organization', id: string) => `/stripe/${type}/setup-intent/${id}`,
		setMyDefaultCard: (cardId: string) => `/stripe/set-default-card/${cardId}`,
		setPartnerDefaultCard: (type: 'location' | 'organization', id: string) => `/stripe/${type}/set-default-card/${id}`,
		removeCard: (cardId: string) => `/stripe/remove-card/${cardId}`,
	},
	teams: {
		list: '/teams',
		item: (id: string) => `/teams/${id}`,
		removeUsers: (id: string) => `/teams/${id}/remove_users`,
	},
	twoFactorAuth: {
		setup: '/2fa/setup',
		check: '/2fa/check',
		isActivated: '/2fa/is-activated',
	},
	user: {
		customers: {
			list: '/customers',
			item: (id: string) => `/customers/${id}`,
		},
		impersonate: (id: string) => `/users/${id}/impersonate`,
		disable2fa: (id: string) => `/users/${id}/disable-2fa`,
	},
	vouchers: {
		list: '/vouchers',
		available: '/vouchers/available',
		publicDetail: (id: string, code: string) => `/vouchers/${id}/public?code=${code}`,
		onboardingAssign: (id: string, code: string) => `/vouchers/${id}/onboarding/assign_voucher?code=${code}`,
		assign: (id: string) => `/vouchers/${id}/assign_voucher`,
		unassign: (id: string) => `/vouchers/${id}/unassign_voucher`,
		revokeCode: (id: string) => `/vouchers/${id}/revoke_voucher`,
		sendVoucher: (id: string) => `/vouchers/${id}/send_voucher`,
	},
	stateHistories: {
		list: (id: string, resource: string, property: string) => `/${resource}/${id}/state_histories/${property}`,
	},
	upload: {
		media: (iri: Iri) => `${iri}/media`,
	},
	changeState: (iri: Iri) => `${iri}/change_state`,
	transit: (resourceName: string) => `${resourceName}/transit`,

	external: {
		getIpAddress: 'https://ipapi.co/json/',
	},
};